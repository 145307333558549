<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">{{ $t('BILLS.FILTER_BILLS') }}</h3>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="row">
              <div class="col-sm-12 col-md col-xl mb-3 mb-xl-0">
                <div class="input-group">
                  <input
                    v-model="businessSpaceLabel"
                    class="form-control form-control-solid form-control-lg"
                    :placeholder="$t('BILLS.ENTER_BUSINESS_LABEL')"
                    type="text"
                  />
                </div>
              </div>
              <DatePicker
                v-model="range"
                :columns="$screens({ default: 1, lg: 1 })"
                :masks="masks"
                is-range
                is24hr
                locale="hr"
                mode="dateTime"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="d-flex">
                    <div>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-calendar"></i>
                          </span>
                        </div>
                        <input
                          :placeholder="fromText"
                          :value="inputValue.start"
                          class="form-control form-control-solid form-control-lg"
                          v-on="inputEvents.start"
                        />
                      </div>
                    </div>
                    <div class="flex-shrink-0 my-2 mx-3">
                      <i class="la la-arrow-right"></i>
                    </div>
                    <div>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-calendar"></i>
                          </span>
                        </div>
                        <input
                          :placeholder="toText"
                          :value="inputValue.end"
                          class="form-control form-control-solid form-control-lg"
                          v-on="inputEvents.end"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </DatePicker>
              <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
                <button
                  class="btn btn-light-primary btn-block"
                  style="height: 44px"
                  @click.prevent="filter_bills"
                >
                  {{ $t('BILLS.FILTER_BILLS') }}
                </button>
              </div>
            </div>
            <div class="separator separator-dashed my-5"></div>
            <b-table
              v-if="table_visible"
              :busy="!isLoaded"
              :fields="fields"
              :items="bills"
              responsive
              sticky-header="72vh"
              striped
            >
              <template #cell(status)="data">
                <span class="label label-sm label-success p-3"></span>
              </template>
              <template #cell(businessSpaceLabel)="data">
                <span>{{ data.item.businessSpaceLabel }}</span>
              </template>
              <template #cell(printBtn)="data">
                <span>
                  <button
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                    @click="bill_pdf(data.item)"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/Devices/Printer.svg" />
                    </span>
                  </button>
                </span>
              </template>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import formatISO from 'date-fns/formatISO'
import format from 'date-fns/format'
import { mapGetters } from 'vuex'
// import axios from 'axios'
// import parse from 'date-fns/parse'
import ApiService from '@/core/services/api.service'

export default {
  name: 'BillsFilter',
  components: {
    DatePicker,
  },
  data() {
    return {
      fields: [
        {
          key: 'status',
          label: 'STATUS',
          sortable: true,
        },
        {
          key: 'jir',
          label: 'JIR',
          sortable: false,
        },
        {
          key: 'zki',
          label: 'ZKI',
          sortable: false,
        },
        {
          key: 'imei',
          label: 'IMEI',
          sortable: true,
        },
        {
          key: 'businessSpaceLabel',
          label: 'PP',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Iznos',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Datum i vrijeme',
          sortable: true,
          class: 'text-right',
        },
        {
          key: 'printBtn',
          label: '',
          sortable: false,
          class: 'text-right',
        },
      ],
      bills: [],
      totalItems: null,
      isLoaded: false,
      isLoading: false,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      lastTransaction: null,
      fromText: '',
      toText: '',
      businessSpaceLabel: null,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompanyOib',
      'currentUserCompanyId',
    ]),

    table_visible() {
      return this.isLoading || this.isLoaded
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Bills', route: '/bills/filter' },
    ])
  },
  methods: {
    filter_bills() {
      if (this.isLoading) return false

      // TODO clear table
      // TODO show API errors

      this.isLoading = true
      let endpoint = `company/${this.currentUserCompanyId}/search-bills-bsl/`
      let params = {
        'order[createdAt]': 'desc',
      }

      // ! check fields and build query
      if (!this.businessSpaceLabel) {
        this.$notify({
          group: 'user',
          type: 'warn',
          title: 'Business label can not be empty',
        })
        setTimeout(() => (this.isLoading = false), 500)
        return false
      }

      endpoint += this.businessSpaceLabel

      if (this.range.start) {
        params['createdAt[after]'] = formatISO(this.range.start)
        params['order[createdAt]'] = 'asc'
        if (this.range.end)
          params['createdAt[before]'] = formatISO(this.range.end)
      }

      // ? JS query params
      const qs = '?' + new URLSearchParams(params).toString()

      // // ? NODE  query params
      // const querystring = require('querystring')
      // const qs = '?' + querystring.stringify(params)

      ApiService.get(endpoint, qs).then(({ data }) => {
        this.totalItems = data['hydra:member'].length
        this.bills = data['hydra:member'].map((bill) => ({
          id: bill.id,
          jir: bill.jir,
          zki: bill.zki,
          imei: bill.imei,
          businessSpaceLabel: bill.meta.business_space_label,
          amount: bill.amount,
          createdAt: format(new Date(bill.createdAt), 'dd.MM.yyyy @ HH:mm:ss'),
        }))
        this.isLoaded = true
        this.isLoading = false
        this.lastBill = this.bills[this.bills.length - 1]
        this.fromText = this.lastBill.createdAt
        this.toText = 'now'
      })
    },

    bill_pdf(item) {
      let pdf_url = `${process.env.VUE_APP_API_ENDPOINT}/company/${this.currentUserCompanyId}/bill-pdf/${item.id}`
      // location.href = pdf_url
      window.open(pdf_url, '_blank') || window.location.replace(pdf_url)
    },
  },
}
</script>

<style>
.vc-container {
  font-family: 'Poppins', sans-serif !important;
}
</style>
